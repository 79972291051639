import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import NavigationBar from "../components/navigation-bar"
import '../styles/layout.styles.sass'
import '../styles/button.styles.sass'
import '../styles/input.styles.sass'
import '../styles/navigation.styles.sass'
import { Link } from "gatsby"
import LinkedInLogo from "../components/social-icons/linkedin"
import GitHubLogo from "../components/social-icons/github"
import TwitterLogo from "../components/social-icons/twitter"
import EmailLogo from "../components/social-icons/email"
import MenuButton from "../components/menu-button"
import Menu from "../components/menu"

const IndexPage = () => {
  const [isOpen, setOpen] = React.useState(false)

  return (<Layout>
    <Seo title="Full-stack software developer" description="Welcome to Dev's page." meta="fullstack, software, developer, amsterdam" />
    <MenuButton page={'home-page-bar'} open={isOpen} onClickOperation={setOpen} />
    <Menu open={isOpen} onCloseOperation={setOpen} />
    <div className={`page-container home-page-container ${!isOpen ? 'page-container-open' : 'page-container-close'}`}>
      <div className={'content-container'}>
        <div className={'layout-container home-page-layout-container'}>
          <div className={'left'}>
            <div>
              <span className={'name-header'}>
                Hi, I am Dev
              </span>
              <p>
                Software developer by profession. Expertise in building performant, scalable and fault tolerant applications. Love to explore new technologies and framework.
              </p>
              <div className={'social-media-tray'}>
                <a href={'https://www.linkedin.com/in/devayan-sarkar/'} target="_blank" rel="noreferrer">
                  <LinkedInLogo />
                </a>
                <a href={'https://github.com/devayansarkar'} target="_blank" rel="noreferrer">
                  <GitHubLogo />
                </a>
                <a href={'https://twitter.com/devayan_sarkar'} target="_blank" rel="noreferrer" className="twitter">
                  <TwitterLogo />
                </a>
                <a href={'mailto:me@devayansarkar.com'} target="_blank" rel="noreferrer">
                  <EmailLogo />
                </a>
              </div>
              <div>
                <Link to="/contact">
                  <div className={'profile-button secondary'}>
                    Contact me
                  </div>
                </Link>
              </div>
            </div>
          </div>
          <div>
            <div className={'quicklinks-button'}>
              Scroll for quick links
            </div>
          </div>
          <div className={'right'}>
            <div className={'profile'}>
              <div className={'name'}>
                Devayan Kumar Sarkar
              </div>
              <div className={'role'}>
                Fullstack software developer
              </div>
              <div className={'spacer'}>
              </div>
              <div className={'profile-title'}>
                Top skills
              </div>
              <div className={'profile-subtitle'}>
                Backend, frontend, CI/CD and automated tests
              </div>
              <Link to="skill">
                <div className={'profile-button'}>
                  View skills
                </div>
              </Link>
              <div className={'spacer'}>
              </div>
              <div className={'profile-title'}>
                Latest Project
              </div>
              <div className={'profile-subtitle'}>
                Building an Issue tracker application from scratch.
              </div>
              <Link to="projects">
                <div className={'profile-button'}>
                  View projects
                </div>
              </Link>
              <div className={'spacer'}>
              </div>
              <div className={'profile-title'}>
                Latest blog
              </div>
              <div className={'profile-subtitle'}>
              10 Year anniversary of Java 1.8
              </div>
              <Link to="blog">
                <div className={'profile-button'}>
                  View blogs
                </div>
              </Link>
            </div>
          </div>
        </div>
        {/* <div className={'home-page-section'}>
          <div className={'layout-container trivia-container'}>
            <div className={'trivia-block'}>
              <TriviaCard
                title={'Opening Euronext market'}
                image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/youtube.jpg?alt=media&token=7b4d8b93-0c7a-451e-a459-2b26e6d45e88'}
                cardType={'youtube'} />
            </div>
            <div className={'trivia-block'}>
              <TriviaCard
                title={'10k+ downloads for docker image'}
                image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/docker.jpg?alt=media&token=eec31d63-014e-448d-88aa-db51b2ddef9c'}
                cardType={'docker'} />
            </div>
            <div className={'trivia-block'}>
              <TriviaCard
                title={'Best practices of Conversational UI'}
                image={'https://firebasestorage.googleapis.com/v0/b/devayansarkar-97d7e.appspot.com/o/devayanDialogflow.jpg?alt=media&token=cb6117de-5e89-470a-921d-b9ef713196db'}
                cardType={'chatbot-presentation'} />
            </div>
          </div> 
        </div>*/}
        {/* <div className={'home-page-section'}>
          <div className={'home-page-sub-header'}>
            Pet projects
          </div>
          <div>
           {
              projects.map((project) => {
                return <div className={'info'} key={project.title}>
                  <InfoCard info={project} />
                </div>
              })
            }
          </div>
        </div> */}

      </div>
      <div className={'navigation-container-right'}>
        <Link to="/skill"><NavigationBar title={'My skills'} page={'skills'} /></Link>
        <Link to="/blog"> <NavigationBar title={'Blogs'} page={'blogs'} /></Link>
        <Link to="/projects"> <NavigationBar title={'Projects'} page={'projects'} /></Link>
        <Link to="/contact"><NavigationBar title={'Contact me'} page={'contact-me'} /></Link>
      </div>
    </div>
  </Layout >)
}

export default IndexPage
