import { Link } from "gatsby"
import React from "react"
import '../styles/menu.styles.sass'

const Menu = ({ open, onCloseOperation }) => {
    return (
        <div className={`menu ${open ? 'menu-open' : 'menu-close'}`}>
            <div className={'menu-row'}>
                <div className={'menu-item back'}>
                    <button onClick={() => onCloseOperation(false)}><div className={'close-button'}>x</div></button>
                </div>
                <Link to="/" className={'menu-item home-route'}>
                    About me
                </Link>
            </div>
            <div className={'menu-row'}>
                <Link to="/skill" className={'skill-route'}>
                    My skills
                </Link>
                <Link to="/blog" className={'blog-route'}>
                    Blogs
                </Link>
            </div>
            <div className={'menu-row'}>
            <Link to="/projects" className={'projects-route'}>
                    Projects
                </Link>
                <Link to="/contact" className={'contact-route'}>
                    Contact me
                </Link>
            </div>
            <div className={'menu-footer'}>
                Built using gatsby <br />
                Deployed on Firebase
            </div>
        </div>
    )
}

export default Menu