import React from 'react'
import '../styles/menu.styles.sass'

const MenuButton = ({ page, open, onClickOperation }) => {
    return !open && <div className={`menu-button`}>
        <button onClick={() => onClickOperation(true)}>
            <div className={'menu-container'}>
                <div className={`bar ${page}`} />
                <div className={`bar ${page}`} />
                <div className={`bar ${page}`} />
            </div>
        </button>
    </div>
}

export default MenuButton