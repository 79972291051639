import React from 'react'
import PropTypes from 'prop-types'

import '../styles/navigation.styles.sass'
const NavigationBar = (props) => {
    return (
        <div className={`${props.page}-navigation-bar`}>
            <h1>
                {props.title}
            </h1>
        </div>
    )
}

export default NavigationBar

NavigationBar.propTypes = {
    title: PropTypes.string.isRequired,
    page: PropTypes.string.isRequired,
}